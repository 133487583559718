var __decorate = (this && this.__decorate) || function (decorators, target, key, desc) {
    var c = arguments.length, r = c < 3 ? target : desc === null ? desc = Object.getOwnPropertyDescriptor(target, key) : desc, d;
    if (typeof Reflect === "object" && typeof Reflect.decorate === "function") r = Reflect.decorate(decorators, target, key, desc);
    else for (var i = decorators.length - 1; i >= 0; i--) if (d = decorators[i]) r = (c < 3 ? d(r) : c > 3 ? d(target, key, r) : d(target, key)) || r;
    return c > 3 && r && Object.defineProperty(target, key, r), r;
};
import { NgModule, InjectionToken } from '@angular/core';
export var MQTT_SERVICE_OPTIONS = {
    connectOnCreate: true,
    hostname: 'localhost',
    port: 1884,
    path: ''
};
export var MqttServiceConfig = new InjectionToken('NgxMqttServiceConfig');
export var MqttClientService = new InjectionToken('NgxMqttClientService');
var MqttModule = /** @class */ (function () {
    function MqttModule() {
    }
    MqttModule_1 = MqttModule;
    MqttModule.forRoot = function (config, client) {
        return {
            ngModule: MqttModule_1,
            providers: [
                {
                    provide: MqttServiceConfig,
                    useValue: config
                },
                {
                    provide: MqttClientService,
                    useValue: client
                }
            ]
        };
    };
    var MqttModule_1;
    MqttModule = MqttModule_1 = __decorate([
        NgModule()
    ], MqttModule);
    return MqttModule;
}());
export { MqttModule };
